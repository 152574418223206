<template>
  <div class="home">
    <v-row no-gutters>
      <v-col v-show="showpanel" cols="12" :sm="banner" class="spaces">
        <div>
          <v-carousel
            cycle
            :show-arrows="false"
            hide-delimiter-background
            delimiter-icon="mdi-minus"
            :height="windowHeight"
          >
            <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
            ></v-carousel-item>
          </v-carousel>
        </div>
      </v-col>
      <v-col cols="12" :sm="logon" class="spaces">
        <div class="login">
          <div v-show="entryPoint">
            <v-form @submit.prevent="go">
              <v-card flat outlined rounded="0">
                <v-toolbar flat style="margin: 30px 0px 10px">
                  <v-img
                    :lazy-src="require('../assets/images/logo_small.png')"
                    :src="require('../assets/images/logo.png')"
                    height="45"
                    contain
                  ></v-img>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    label="email"
                    v-model="entry.email"
                  ></v-text-field>
                  <v-text-field
                    label="password"
                    v-model="entry.password"
                    :type="showCurrent ? 'text' : 'password'"
                    :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showCurrent = !showCurrent"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions style="padding: 0px 16px">
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="loginx"
                    depressed
                    block
                    :disabled="xlogin"
                  >
                    login
                  </v-btn>
                </v-card-actions>

                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    href="https://www.umohe.com/register"
                    target="_blank"
                    >Register</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red"
                    @click="fromLogin"
                    text
                    :disabled="xforgot"
                  >
                    forgotten?
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </div>
          <div v-show="forgotPoint">
            <v-form @submit.prevent="forget">
              <v-card flat outlined rounded="0">
                <v-toolbar flat style="margin: 30px 0px 10px">
                  <v-img
                    :lazy-src="require('../assets/images/logo_small.png')"
                    :src="require('../assets/images/logo.png')"
                    height="45"
                    contain
                  ></v-img>
                </v-toolbar>
                <v-toolbar flat dense>
                  <v-spacer></v-spacer>
                  <h3>Forgotten Password</h3>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-subtitle>
                  Submit your email below. A link will be sent to you in your
                  email to continue a password reset.
                </v-card-subtitle>
                <v-card-text>
                  <v-text-field label="Email" v-model="myEmail"></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-row style="padding: 0px 10px 10px">
                    <v-col sm="8">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="primary"
                            type="submit"
                            depressed
                            block
                            @click="submitforgot"
                          >
                            submit
                          </v-btn>
                        </template>
                        <span>Subsmit for your reset link in your email</span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            color="primary"
                            text
                            v-on="on"
                            @click="backToLogin"
                          >
                            back
                          </v-btn>
                        </template>
                        <span>Back to log login </span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-form>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import { VBtn, VSpacer } from "vuetify/lib";
export default {
  components: { VBtn, VSpacer },
  data() {
    return {
      myEmail: null,
      entryPoint: true,
      forgotPoint: false,
      showCurrent: false,
      cycle: true,
      slides: [
        {
          src: require("../assets/images/caro1.png"),
        },
        {
          src: require("../assets/images/caro2.png"),
        },
        {
          src: require("../assets/images/caro3.png"),
        },
        {
          src: require("../assets/images/caro4.png"),
        },
      ],
      forgotDialog: false,
      loginx: false,
      xlogin: false,
      forgotx: false,
      xforgot: false,
      registerx: false,
      xregister: false,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      // showpanel: true,
      banner: 6,
      logon: 6,
      txt: "",
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ...",
      },
      entry: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    showpanel: {
      get() {
        if (this.windowWidth <= 600) {
          return false;
        } else {
          return true;
        }
      },
      set(val) {
        return val;
      },
    },
  },
  watch: {
    windowWidth: function (value) {
      console.log(value);
      if (value <= 600) {
        console.log("less than");
        this.banner = 0;
        this.logon = 12;
        this.showpanel = false;
      } else {
        console.log("greater than");
        this.banner = 6;
        this.logon = 6;
        this.showpanel = true;
      }
    },
  },
  methods: {
    submitforgot() {
      this.snack.bar = true;
      let email = this.myEmail;
      let site = "my";
      let da = { email, site };
      let self = this;
      Restful.accounts.entry
        .forgot(da)
        .then((response) => {
          console.log(response);
          this.snack.color = "primary";
          this.snack.text = response.data;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.bar = true;
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    fromLogin() {
      this.entryPoint = false;
      this.forgotPoint = true;
    },
    backToLogin() {
      this.entryPoint = true;
      this.forgotPoint = false;
    },
    forget() {},
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    go() {
      this.snack.bar = true;
      this.loginx = true;
      this.xforgot = true;
      this.xregister = true;
      // let dat

      console.log(this.entry);
      let self = this;
      Restful.accounts.entry
        .login(this.entry)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            console.log("good");
            let name =
              response.data.salutation +
              " " +
              response.data.first_name +
              " " +
              response.data.last_name;
            let role = response.data.role;
            let subTypeId = response.data.subscriber_type;
            let subscription = response.data.subscriptions;
            let pix = response.data.pix;
            let token = response.data.token;
            let busines = response.data.busines;
            let dependency = response.data.dependency;
            let hasDependancy = response.data.hasDependancy;

            // localstorage
            localStorage.setItem("user.name", name);
            localStorage.setItem("user.subscriber_type", subTypeId);
            localStorage.setItem("user.subscriber_type", subTypeId);
            localStorage.setItem(
              "user.subscription",
              JSON.stringify(subscription)
            );
            localStorage.setItem("user.role", role);
            localStorage.setItem("user.pix", pix);
            localStorage.setItem("user.token", token);
            localStorage.setItem("user.busines", busines);
            localStorage.setItem("user.dependency", dependency);
            localStorage.setItem("user.hasDependancy", hasDependancy);

            if (subTypeId === "medics") {
              this.$router.push({
                name: "medics.dash",
              });
            } else if (subTypeId === "pharmacy") {
              this.$router.push({
                name: "pharma.dash",
              });
            } else if (subTypeId === "laboratory") {
              this.$router.push({
                name: "lab.dash",
              });
            } else if (subTypeId === "delivery") {
              this.$router.push({
                name: "delivery.dash",
              });
            } else if (subTypeId === "patients") {
              this.$router.push({
                name: "patient.dash",
              });
            } else {
              console.log();
            }
          } else {
            this.snack.bar = true;
            this.snack.color = "error";
            this.snack.text = "Sorry, cannot access the service";
          }
          this.loginx = false;
          this.xforgot = false;
          this.xregister = true;
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response === undefined) {
            self.snack.bar = true;
            self.snack.color = "error";
            self.snack.text =
              "Please check internet connection or wait for a while and try logging in again.";
          } else {
            console.log(error.response.data);
            self.snack.bar = true;
            self.snack.color = "error";
            self.snack.text = error.response.data;
          }
          self.loginx = false;
          self.xforgot = false;
          self.xregister = true;
        });
    },
    closeForgot(p) {
      console.log(p);
      this.forgotDialog = p.state;
    },
    submitForgot(p) {
      this.snack.bar = true;
      this.xlogin = true;
      this.xforgot = true;

      this.forgotDialog = p.state;
      console.log(p.data);
      let email = p.data;
      let site = "my";
      let data = { email, site };
      console.log(data);

      let self = this;
      Restful.entry
        .forgot(data)
        .then((response) => {
          this.snack.bar = true;
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.xlogin = false;
          this.xforgot = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.snack.bar = true;
          self.snack.color = "error";
          self.snack.text = error.response.data.message;
          self.xlogin = false;
          self.xforgot = false;
        });
    },
    register() {
      this.registerx = true;
      this.xforgot = true;
      this.xlogin = true;
      setTimeout(() => {
        this.registerx = false;
        this.xforgot = false;
        this.xlogin = false;
      }, 2000);
    },
  },
};
</script>
